<template>
	<main>
		<div class="main">
			<h1 class="title">ABOUT US</h1>
			<div class="min-title">互联网思维+创新模式，<br/>助力客户多方位升级！</div>
		</div>
		<div class="about">
			<div class=" image"></div>
			<div class=" info">
				<div class="tit">湖南森网网络科技有限公司</div>
				<div class="desc">湖南森网网络科技有限公司是专注于为客户创造有效价值的团队，提供互联网一站式解决方案。助力客户有效的完成互联网业务的推广和增值，提供全方位的互联网技术和业务支撑，提供一站式的设计，开发，营销，推广流程。提升行业竞争力，让客户的产品或品牌带来正面价值和非凡的用户体验。</div>
				<a-link to="/contact" class="block btn">联系我们+</a-link>
			</div>
		</div>
		<div class="sense">
			<div class="title">核心价值观</div>
			<div class="min-title">为客户带来持续有效的业务增值</div>
			<div class="list">
				<div class="center">
					<div class="left item">
						<div class="icon"><i class="iconfont icon-chengjiu"></i></div>
						<div class="tit">成就客户</div>
						<div class="desc">我们坚持以客户为中心，快速响应客户需求，持续为客户创造长期价值进而成就客户。为客户提供有效服务，是我们工作的方向和价值评价的标尺，成就客户就是成就我们自己。</div>
					</div>
					<div class="left item">
						<div class="icon"><i class="iconfont icon-chuangyi"></i></div>
						<div class="tit">创新至上</div>
						<div class="desc">我们在现有的思维模式中提出有别于常规或常人思路的见解为导向，本着理想化的需要或为满足用户需求,进行改进和创造新的体验、设计表现形式、交互，并能获得有益的效果</div>
					</div>
					<div class="left item">
						<div class="icon"><i class="iconfont icon-zuanshi"></i></div>
						<div class="tit">追求卓越</div>
						<div class="desc">我们的卓越不是一个标准，而是一种境界。它不是优秀，它是优秀中的最优。卓越是一种追求，它在于将自身的优势、能力，以及所能使用的资源，发挥到极致的一种状态。</div>
					</div>
				</div>
			</div>
		</div>
		<div class="total">
			<div class="center">
				<div class=" item">
					<div class="number"><i>10</i>年</div>
					<div class="tit">行业经验</div>
				</div>
				<div class=" item">
					<div class="number"><i>100</i>+</div>
					<div class="tit">成功案例</div>
				</div>
				<div class=" item">
					<div class="number"><i>60</i>+</div>
					<div class="tit">合作客户</div>
				</div>
				<div class=" item">
					<div class="number"><i>∞</i>大</div>
					<div class="tit">服务理念</div>
				</div>
			</div>
		</div>
		<components-contact/>
	</main>
</template>

<script>
import ComponentsContact from "@/components/views/index/contact.vue"
export default {
	components:{ComponentsContact}
}
</script>

<style lang="scss" scoped>
.main{text-align: center;
	.title{font-size: 60px;font-weight: bold;line-height: 1; color:$main;padding:170px 0 100px;}
	.min-title{font-size: 40px;color:$main;}
}
.about{display: flex;margin-top: 100px;background: $border;
	.image{width: 50%;background: url("~@/assets/image/1.jpg") no-repeat center center;background-size: cover;}
	.info{flex: 1;padding:80px 100px;
		.tit{font-size: 30px;font-weight: bold;color:$main;}
		.desc{width: 80%; font-size: 16px;margin-top: 20px;line-height: 32px;color:#666;}
		.btn{width: 120px;height:44px;text-align: center;line-height:44px;color:$main;border: 2px solid $main;margin-top: 40px;}
	}
}
.sense{padding-top: 100px;text-align: center;
	.title{display: inline-block; font-size: 34px;position: relative;line-height: 1;}
	.title::after{content: "";display: block;position: absolute; bottom:2px;width: 100%;height:4px;background: $main;z-index: 0;opacity: .6}
	.min-title{font-size: 16px;margin-top: 20px;color:#666;}
	.list{
		.center{width: 1200px;margin: 100px auto 0;display: flex;
			.item{flex:1;margin-right: 70px;padding: 50px;border: 1px solid #999;
				.icon{width: 100px;height:100px;margin: 0 auto;
					.iconfont{font-size: 90px;color:$main;}
				}
				.tit{margin-top: 50px;text-align: center;font-size: 20px;}
				.desc{font-size: 16px;color:#999;margin-top: 30px;line-height: 28px; text-align:left;}
			}
			.item:nth-last-child(1){margin-right: 0;}
		}
	}
}
.total{background: $main;padding: 100px 0;margin-top: 100px;
	.center{width: 1200px;margin: 0 auto;display: flex;color:#fff;
		.item{flex: 1;text-align: center;
			.number{font-size: 18px;
				i{font-size: 60px;}
			}
			.tit{font-size: 16px;margin-top: 20px;}
		}
	}
}

@media screen and (max-width: 1220px) {
	.main{
		.title{font-size:m(60px);padding:m(170px) 0 m(100px);}
		.min-title{font-size: m(40px);}
	}
	.about{margin-top: m(100px);
		.info{padding:m(80px) m(100px);
			.tit{font-size: m(30px);}
			.desc{width: 100%; font-size: 14px;margin-top:m(20px);line-height:m(32px);}
			.btn{width:m( 120px);height:m(44px);line-height:m(44px);margin-top: m(40px);font-size: 14px;}
		}
	}
	.sense{padding-top:m( 100px);
		.title{ font-size: m(34px)}
		.min-title{font-size: 14px;margin-top:m( 20px);}
		.list{
			.center{width:auto;padding: 0 25px; margin: m(100px) auto 0;
				.item{flex:1;margin-right:m( 50px);padding:m(30px);
					.icon{width:m(100px);height:m(100px);margin: 0 auto;
						.iconfont{font-size: m(90px);}
					}
					.tit{margin-top: m(50px);font-size: 18px;}
					.desc{font-size: 14px;margin-top:m(30px);line-height:m( 28px); }
				}
			}
		}
	}
	.total{padding:m(100px) 0;margin-top: m(100px);
		.center{width:auto;padding: 0 25px;
			.item{
				.number{font-size: 16px;
					i{font-size: m(60px);}
				}
				.tit{font-size: 14px;margin-top:m(20px);}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.main{
		.title{font-size:x(60px);padding:x(170px) 0 x(100px);}
		.min-title{font-size: x(40px);}
	}
	.about{margin-top: x(100px);flex-direction: column;padding: 0 15px;background:none;display: block;
		.image{height:200px;width: 100%;}
		.info{padding:50px 0;
			.tit{font-size: 22px;}
			.desc{width: 100%; font-size: 16px;margin-top:x(20px);line-height:32px;}
			.btn{width:m( 120px);height:m(44px);line-height:m(44px);margin: m(40px) auto 0;font-size: 14px;}
		}
	}
	.sense{padding-top:x( 100px);
		.title{ font-size:24px}
		.min-title{font-size: 16px;margin-top:x( 20px);}
		.list{
			.center{width:auto;padding: 0 15px; margin: x(100px) auto 0;flex-direction: column;
				.item{flex:1;margin-right:0;padding:x(30px);margin-bottom: 20px;
					.icon{width:x(100px);height:x(100px);margin: 0 auto;
						.iconfont{font-size: x(90px);}
					}
					.tit{margin-top: x(50px);font-size: 18px;}
					.desc{font-size: 14px;margin-top:x(30px);line-height:24px; }
				}
			}
		}
	}
	.total{padding:x(100px) 0;margin-top: x(100px);
		.center{width:auto;padding: 0 15px;
			.item{
				.number{font-size: 16px;
					i{font-size: x(60px);}
				}
				.tit{font-size: 14px;margin-top:x(20px);}
			}
		}
	}
}
</style>

